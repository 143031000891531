<template>
    <div>
        <!-- 主体 -->
        <LiefengContent>
            <template v-slot:title>{{ "社区行为数据统计" }}</template>
            <!-- 头部搜索层 -->
            <template v-slot:toolsbarRight>
                <Form :label-colon="true" :inline="true" class="search">
                    <FormItem>
                        <LiefengCascaderCity :orgCode="4401" :resetNum="resetNum" @changeCasader="changeCasader" :CascaderList="cascaderIdList"></LiefengCascaderCity>
                    </FormItem>
                    <FormItem>
                        <Select v-model="onlineType" filterable transfer>
                            <!-- 防止报错，使用了index -->
                            <Option value="">全部社区</Option>
                            <Option value="1">市级示范社区</Option>
                            <Option value="2">区级示范社区</Option>
                        </Select>
                    </FormItem>
                    <FormItem>
                        <DatePicker :transfer="true" v-model="searchDate" type="daterange" style="width: 400px" placeholder="请选择开始日期和结束日期" @on-change="changeDate"></DatePicker>
                    </FormItem>

                    <Button style="margin-right: 10px" type="primary" icon="ios-search" @click="searchBtn">查询</Button>
                    <Button style="margin-right: 10px" type="success" @click="resetBtn" icon="ios-refresh">重置</Button>
                </Form>
            </template>
            <!-- 分页层 -->
            <template v-slot:contentArea>
                <!-- 分页table -->
                <LiefengTable
                    :talbeColumns="pageForm.talbeColumns"
                    :tableData="pageForm.tableData"
                    :loading="controlForm.loading"
                    :fixTable="true"
                    :curPage="pageForm.page"
                    :total="pageForm.total"
                    :pagesizeOpts="[20, 30, 50, 100]"
                    :page-size="pageForm.pageSize"
                    @hadlePageSize="changeTableSize"
                ></LiefengTable>
            </template>
            <!-- 新增modal -->
        </LiefengContent>
    </div>
</template>

<script>
import LiefengContent from "@/components/LiefengContent3"
import LiefengTable from "@/components/LiefengTable"
import LiefengCascaderCity from "@/components/LiefengCascaderCity"

export default {
    components: { LiefengContent, LiefengTable, LiefengCascaderCity },
    data() {
        return {
            //分页参数
            pageForm: {
                talbeColumns: [
                    {
                        title: "区",
                        key: "zoneName",
                        minWidth: 200,
                        align: "center",
                    },
                    {
                        title: "街道",
                        key: "streetName",
                        minWidth: 200,
                        align: "center",
                    },
                    {
                        title: "社区",
                        key: "communityName",
                        minWidth: 200,
                        align: "center",
                    },
                    {
                        title: "居民接受信息服务数据（人次）",
                        key: "view",
                        minWidth: 200,
                        align: "center",
                    },
                    {
                        title: "居民参与互动服务统计数据（人次）",
                        key: "join",
                        minWidth: 200,
                        align: "center",
                    },
                ],
                tableData: [],
                loading: false,
                page: 1,
                pageSize: 20,
                total: 0,
                currentPage: 0,
            },
            //控制的一些属性
            controlForm: {
                loading: false,
            },
            //查询参数
            searchForm: {},

            // 清空选择市区街道数组
            resetNum: 1,
            // 存放查询接口的社区数组
            cascaderIdList: [],

            onlineType: "",

            searchDate:[]
        }
    },
    //create函数,刚加载页面时调用
    async created() {
        await this.selectPage()
    },
    methods: {
        changeDate(val) {
            this.searchDate[0] = val[0];
            this.searchDate[1] = val[1];
        },
        // 城市组件
        changeCasader(val) {
            this.cascaderIdList = val
        },
        /*----- 分页事件部分 -------*/

        //分页事件改变
        changeTableSize(val) {
            this.pageForm.page = val.page
            this.pageForm.pageSize = val.pageSize
            this.selectPage()
        },
        //查询按钮
        searchBtn() {
            this.pageForm.page = 1
            this.selectPage()
        },
        //重置按钮
        resetBtn() {
            this.resetNum++
            this.pageForm.page = 1
            this.onlineType = ""
            this.cascaderIdList = []
            this.searchDate = []
            this.selectPage()
        },
        // 方法部分
        /*----- 接口部分 -------*/
        async selectPage() {
            this.controlForm.loading = true
            await this.$get("/gateway/api/bi/manage/userAction/selectOrgPage", {
                onlineType: this.onlineType,
                orgCode: this.cascaderIdList[this.cascaderIdList.length - 1],
                startDate: this.searchDate.length ? this.searchDate[0] : '',
                endDate: this.searchDate.length ? this.searchDate[1] : '',
                page:this.pageForm.page,
                pageSize:this.pageForm.pageSize
            }).then(res => {
                this.controlForm.loading = false
                this.pageForm.tableData = []
                if (res.code == 200) {
                    this.pageForm.tableData = res.dataList
                    this.pageForm.total = res.maxCount
                    this.pageForm.currentPage = res.currentPage
                } else {
                    this.$Message.error({
                        content: "获取列表失败",
                        background: true,
                    })
                    return
                }
            })
        },
    },
}
</script>

<style lang="less" scoped>
.table-left {
    float: left;
    width: 160px;
    /deep/.ivu-menu {
        width: 160px !important;
    }
}
.none-modal {
    /deep/.ivu-modal-close {
        display: none;
    }
}
/deep/.ivu-form-item-content {
    display: flex;
}
</style>